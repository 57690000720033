import Vue from 'vue'
import Vuex from 'vuex'
import {info} from '@/api/tanao'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navBg: '',
    info:JSON.parse(window.localStorage.getItem('info'))
  },
  getters: {
  },
  mutations: {
    changeVuex(state, obj) {
      state[obj.key] = obj.value
    }
  },
  actions: {
    getInfo({ state, commit}) {

      // return
      info().then((res) => {
        if(res.code != 200) return 
        let obj = {}
        res.data.forEach((v) => {
          obj[v.infoKey] = v.infoValue
        })
        commit('changeVuex', {key: 'info', value: obj})
        window.localStorage.setItem('info', JSON.stringify(obj))
      })
    }
    
  },
  modules: {
  }
})
